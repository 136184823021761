<template>
  <a-row :gutter="[16,16]">
    <a-col
      :md="8"
      :span="24">
      <image-carousel v-if="plan.pictures">
        <template #images>
          <div
            v-for="(item, index) in plan?.pictures"
            :key="item.id">
            <a-card>
              <img
                :style="{ width: '100%', objectFit: 'contain', marginBottom: '2rem', height: '300px' }"
                :src="item"
                alt="">
              <a-card-meta :title="`${index + 1}/${plan.pictures.length} этаж`" />
            </a-card>
          </div>
        </template>
      </image-carousel>
    </a-col>

    <a-col
      :md="16"
      :span="24">
      <a-row :gutter="[16,16]">
        <a-col
          v-if="isSamePlansAiVisible"
          :span="24">
          <a-button
            :disabled="!plan?.pictures || plan.pictures.length > 1"
            :style="{ width: '100%' }"
            @click="openSamePlansAiModal">
            Поиск похожих планировок
            <a-tag
              color="#f50"
              :style="{marginLeft: '.5rem'}">
              Beta
            </a-tag>
            <a-tooltip
              v-if="plan?.pictures?.length > 1"
              placement="right">
              <template #title>
                <span>Работает только с одноэтажными планировками</span>
              </template>
              <question-circle-outlined style="margin-left: 5px" />
            </a-tooltip>
          </a-button>
        </a-col>
        <a-col
          :span="24"
          :md="12">
          <a-button
            :disabled="!plan.pictures"
            :style="{ width: '100%' }"
            @click="openCompareModal">
            Открыть сравнение
          </a-button>
        </a-col>
        <a-col
          v-if="planType === PlanType.Type2d"
          :span="24"
          :md="12">
          <a-button
            :style="{ width: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }"
            @click="downloadGluedImage">
            Скачать результат (многоэтажная обработка)
          </a-button>
        </a-col>
        <a-col
          v-if="!isUserSupervisor && planType === PlanType.Type2d"
          :span="24"
          :md="12">
          <a-button
            :style="{ width: '100%' }"
            :href="plan?.links?.neural?.editor"
            target="_blank"
            rel="noopener noreferrer">
            Открыть Конструктор
          </a-button>
        </a-col>

        <a-col
          v-if="isUserAdmin && servicePlanId"
          :span="24"
          :md="12">
          <router-link
            :disabled="!servicePlanId"
            :to="{ name: 'plans-show', params: { id: servicePlanId } }">
            <a-button :style="{ width: '100%' }">
              [Сервис] Открыть планировку
            </a-button>
          </router-link>
        </a-col>
        <a-col
          v-if="isUserAdmin || isUserNinja"
          :span="24"
          :md="12">
          <a-button
            :style="{ width: '100%' }"
            @click="openModal">
            Поиск похожих планировок
          </a-button>
        </a-col>
        <a-col
          :span="24"
          :md="12">
          <merge-crm-plan-button
            :current-plan-id="plan.id"
            :type="type"
            text-button="Слияние планировки"
            @after-ok="loadData" />
        </a-col>
        <a-col
          v-if="isWidgetMaterialVisible && planType === PlanType.Type3d"
          :span="24">
          <a-button
            :style="{ width: '100%' }"
            @click="openMaterialSelectionModal">
            Выбор вариантов
          </a-button>
        </a-col>
        <a-col
          v-if="publicPlanUrl"
          :span="24">
          <a-button
            :style="{ width: '100%' }"
            :href="publicPlanUrl"
            target="_blank"
            rel="noopener noreferrer">
            Открыть публичную ссылку
          </a-button>
        </a-col>
      </a-row>

      <plan-description
        title="Описание"
        :description="plan?.description"
        :value="plan.description"
        :is-editing-available="true"
        @change-description="changeDescription" />

      <plan-description
        title="Описание апартамента"
        :description="plan.apartment_description" />

      <div v-if="plan?.references?.length > 0 && isPlanDescriptionAndRendersVisible">
        <a-divider>Референсы</a-divider>
        <image-viewer
          :style="{display: 'flex', gap: '20px', flexWrap: 'wrap'}"
          :images="plan.references">
          <div
            v-for="(item) in plan.references"
            :key="item.id">
            <img
              :style="{'object-fit': 'contain'}"
              :src="item"
              loading="lazy"
              height="100"
              width="100"
              alt="References">
          </div>
        </image-viewer>
      </div>
    </a-col>
  </a-row>

  <a-modal
    v-if="isSamePlansAiModalVisible"
    v-model:open="isSamePlansAiModalVisible"
    :footer="null"
    width="100%"
    wrap-class-name="full-modal"
    @close="closeSamePlansAiModal">
    <template #title>
      Поиск похожих планировок
      <a-tag
        color="#f50">
        Beta
      </a-tag>
    </template>
    <same-plans-ai-list
      :plan-type="type"
      :plan-data="planData"
      :original-plan-img="plan?.picture"
      :plan-image="planData?.pictures"
      @close="closeSamePlansAiModal" />
  </a-modal>

  <a-modal
    :open="isCompareModalVisible"
    :destroy-on-close="true"
    :footer="null"
    title="Сравнение"
    centered
    :style="{ width: '100%', top: '8px' }"
    @cancel="closeCompareModal">
    <plan-compare-carousel
      :originals="compareData.originals"
      :svgs="compareData.svgs"
      :top-views="compareData.topViews" />
  </a-modal>

  <a-modal
    v-if="samePlansVisible"
    v-model:open="samePlansVisible"
    :footer="null"
    title="Поиск похожих планировок"
    width="100%"
    wrap-class-name="full-modal"
    @close="closeModal">
    <same-plans-list
      :plan-data="plan"
      :type="type"
      :plan-image="plan.pictures"
      @close="closeModal"
      @update-page="loadData" />
  </a-modal>

  <material-selection-modal
    :open="isMaterialSelectionModalVisible"
    :service-plan-id="plan?.plan?.id ?? null"
    :crm-plan-id="plan?.id ?? null"
    :crm-plan-widget-variants="plan?.widget_variants ?? []"
    @close="closeMaterialSelectionModal" />
</template>

<script setup>
import {
  ref, computed,
} from 'vue';
import { useStore } from 'vuex';
import { httpClient } from '@/httpClient';
import config from '@/config'; // eslint-disable-line import/extensions
import {
  QuestionCircleOutlined,
} from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import ImageCarousel from '@/Components/Common/ImageCarousel.vue';
import MergeCrmPlanButton from '@/Components/CRM/Plans/MergeCrmPlanButton.vue';
import SamePlansAiList from '@/Components/CRM/Plans/SamePlansAiList.vue';
import PlanCompareCarousel from '@/Components/CRM/Plans/CompareCarousel.vue';
import SamePlansList from '@/Components/CRM/Plans/SamePlansList.vue';
import { trackGoal } from '@/helpers/tracking';
import getFlag from '@/helpers/getFlag';
import ImageViewer from '@/Components/Common/ImageViewer.vue';
import MaterialSelectionModal from '@/Components/CRM/Plans/Modals/MaterialSelectionModal.vue';
import { PlanType } from '@/enums/planType';
import { downloadFile } from '@/helpers/downloadFile';
import PlanDescription from './PlanDescription.vue';

const props = defineProps({
  plan: {
    type: Object,
    default: () => ({}),
  },
  planId: {
    type: Number,
    default: 1,
  },
  isUserSupervisor: {
    type: Boolean,
    default: false,
  },
  isUserAdmin: {
    type: Boolean,
    default: false,
  },
  isUserNinja: {
    type: Boolean,
    default: false,
  },
  apartmentDescription: {
    type: String,
    default: '',
  },
  loadData: {
    type: Function,
    required: true,
  },
  planType: {
    type: String,
    default: '',
  },
  servicePlanId: {
    type: String,
    default: '',
  },
  compareData: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const isSamePlansAiVisible = getFlag('isSamePlansAiVisible');
const isWidgetMaterialVisible = getFlag('isWidgetMaterialVisible');
const isPlanDescriptionAndRendersVisible = getFlag('isPlanDescriptionAndRendersVisible');

const samePlansVisible = ref(false);
const isSamePlansAiModalVisible = ref(false);
const isCompareModalVisible = ref(false);
const isMaterialSelectionModalVisible = ref(false);

const openModal = () => {
  trackGoal('same_plans_search');

  samePlansVisible.value = true;
};

const closeModal = () => {
  samePlansVisible.value = false;
};

const openSamePlansAiModal = () => {
  trackGoal('same_plans_ai_search');

  isSamePlansAiModalVisible.value = true;
};

const closeSamePlansAiModal = () => {
  isSamePlansAiModalVisible.value = false;
};

const openCompareModal = () => (isCompareModalVisible.value = true);

const closeCompareModal = () => {
  isCompareModalVisible.value = false;
};

const openMaterialSelectionModal = () => {
  isMaterialSelectionModalVisible.value = true;
};

const closeMaterialSelectionModal = () => {
  isMaterialSelectionModalVisible.value = false;
};

const downloadGluedImage = () => {
  const messageKey = `download-glued-image-${Date.now()}`;
  message.loading({
    duration: 0,
    content: 'Генерируем изображение',
    key: messageKey,
  });

  httpClient.get(`/api/crm/plans/${props.plan.id}/get_glued_picture`)
    .then(({ data }) => {
      if (data?.url) {
        const fileName = `glued_image_${props.plan.id}.jpg`;
        downloadFile(data.url, fileName);
      }
    })
    .catch(() => {
      message.error('Что-то пошло не так :(');
    })
    .finally(() => {
      message.destroy(messageKey);
    });
};

const publicPlanUrl = computed(() => {
  const { servicePlanId } = props;
  const { publicUrl } = config;
  if (!servicePlanId || !publicUrl) {
    return null;
  }

  return `${publicUrl}/${servicePlanId}`;
});

const changeDescription = (newDescription) => {
  store.dispatch('crm/changePlanDescription', { id: props.planId, description: newDescription })
    .finally(() => {
      props.loadData();
    });
};

</script>

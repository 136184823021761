export const LosslessFormat = {
  Exr: 'exr',
  Png: 'png',
};

export const PanoramasTypeNames = {
  [LosslessFormat.Exr]: 'exr',
  [LosslessFormat.Png]: 'png',
};

export const LosslessFormatOptions = Object.entries(PanoramasTypeNames)
  .map(([value, label]) => ({ value, label }));

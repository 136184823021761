import { RtxPreset } from '@/enums/RtxPreset';
import { PanoramasType, PanoramasTypeOptions } from '@/enums/PanoramasType';
import { TopViewsProjectionMode, TopViewsProjectionModeOptions } from '@/enums/TopViewsProjectionMode';
import { LosslessFormatOptions, LosslessFormat } from '@/enums/losslessFormatOptions';
import { BuildPriority } from '@/enums/BuildPriority';
import {
  Steps, OptionTypes, getOptionsAndRules as getOptionsAndRulesBase, getRulesForOptions,
} from '@/composables/serviceFormOptions';
import getFlag from '@/helpers/getFlag';

import store from '@/store';
import { CameraNavigationMode } from '@/enums/CameraNavigationMode';
import { useCameraNavigationOptions } from '@/composables/useCameraNavigationOptions';

const getNeuralOptions = () => [
  {
    type: OptionTypes.MultipleFile,
    label: 'Планировка',
    name: 'plans',
  },
  {
    type: OptionTypes.File,
    label: 'Маска',
    name: 'mask',
  },
  {
    type: OptionTypes.Switch,
    label: 'Лог',
    name: 'isNeuralLogEnabled',
  },
];

const getNeuralRules = () => ({
  plans: [
    {
      required: true,
      type: 'array',
    },
  ],
  mask: [],
  isNeuralLogEnabled: [],
});

const getFurnitureOptions = (steps) => {
  const options = [
    {
      type: OptionTypes.Switch,
      label: 'Расстановка света',
      name: 'isFurnitureLightsEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Расстановка штор и радиаторов',
      name: 'isFurnitureCurtainsRadiatorsEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Расстановка мебели',
      name: 'isFurnitureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Схлопывание балконных стен',
      name: 'isFurnitureGlassWallsCollapseEnabled',
    },
    {
      type: OptionTypes.Select,
      label: 'Дизайн документ',
      name: 'furnitureDesignName',
      options: store.state.furniture.designFiles
        .map((designFiles) => ({ label: designFiles.name, value: designFiles.name })),
    },
  ];

  if (steps.includes(Steps.Neural)) {
    return options;
  }

  return [
    {
      type: OptionTypes.File,
      label: 'JSON',
      name: 'json',
    },
    {
      type: OptionTypes.MultipleFile,
      label: 'Планировка',
      name: 'plans',
    },
    ...options,
  ];
};

const getFurnitureRules = () => ({
  plans: [],
  json: [
    {
      required: true,
      type: 'object',
    },
  ],
  isFurnitureLightsEnabled: [],
  isFurnitureCurtainsRadiatorsEnabled: [],
  isFurnitureEnabled: [],
  isFurnitureGlassWallsCollapseEnabled: [],
  furnitureDesignName: [],
});

const getUnrealOptions = (steps) => {
  const options = [];
  const isEnvironmentVisible = getFlag('isEnvironmentVisible');
  const isNewPlanOptionsVisible = getFlag('isNewPlanOptionsVisible');
  const {
    filteredOptions: filteredCameraNavigationOptions,
    isSelectVisible: isCameraNavigationSelectVisible,
  } = useCameraNavigationOptions();
  if (!steps.includes(Steps.Neural) && !steps.includes(Steps.Furniture)) {
    options.push(
      {
        type: OptionTypes.File,
        label: 'JSON',
        name: 'json',
      },
      {
        type: OptionTypes.MultipleFile,
        label: 'Планировка',
        name: 'plans',
      },
    );
  }

  options.push(
    {
      type: OptionTypes.Select,
      label: 'Пресет RTX',
      name: 'unrealRtxPreset',
      options: Object.entries(RtxPreset).map((item) => ({ label: item[0], value: item[1] })),
    },
    {
      type: OptionTypes.Select,
      label: 'Пресет RTX для вида сверху',
      name: 'unrealRtxPresetTop',
      options: Object.entries(RtxPreset).map((item) => ({ label: item[0], value: item[1] })),
    },

    {
      type: OptionTypes.Switch,
      label: 'Панорамы',
      name: 'isUnrealPanoramasCaptureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Съемка сверху',
      name: 'isUnrealTopViewsCaptureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Съемка сверху вокруг строения',
      name: 'isUnrealTopDownViewsCaptureEnabled',
    },
    {
      type: OptionTypes.Number,
      label: 'Количество фотографий вокруг строения',
      name: 'unrealTopDownViewCount',
    },
    {
      type: OptionTypes.Switch,
      label: 'Срединный срез',
      name: 'isUnrealMiddleCutCaptureEnabled',
    },
    {
      type: OptionTypes.Number,
      label: 'Высота срединного среза',
      name: 'unrealMiddleCutHeight',
    },
    {
      type: OptionTypes.Select,
      label: 'Выбор стилей',
      name: 'styleId',
      options: store.state.substances.stylesList
        .map((stylesList) => ({ label: stylesList.name, value: stylesList.id })),
    },
  );

  if (isEnvironmentVisible) {
    options.push(
      {
        type: OptionTypes.Select,
        label: 'Окружения',
        name: 'unrealEnvironmentId',
      },
    );
  }

  if (isNewPlanOptionsVisible) {
    options.push(
      {
        type: OptionTypes.Select,
        label: 'Тип панорамы',
        name: 'panoramasType',
        options: PanoramasTypeOptions,
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить линейку',
        name: 'isRulerEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить интерактивный вид сверху',
        name: 'isInteractiveTopViewEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить вид сверху в миниплане',
        name: 'isTopViewAsMiniPlanEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Скрыть необязательную мебель',
        name: 'isHideOptionalFurnitureEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Прозрачный фон',
        name: 'isTransparentTopViewEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Сжатие в WEBP',
        name: 'isWebpEnabled',
      },
      {
        type: OptionTypes.Number,
        label: 'CameraISO',
        name: 'cameraIso',
      },
      {
        type: OptionTypes.Select,
        label: 'Тип проекции для топ видов',
        name: 'projectionMode',
        options: TopViewsProjectionModeOptions,
      },
      {
        type: OptionTypes.Select,
        label: 'Формат изображения глубины',
        name: 'losslessFormat',
        options: LosslessFormatOptions,
      },
    );

    if (isCameraNavigationSelectVisible.value) {
      options.push({
        type: OptionTypes.Select,
        label: 'Тип навигации',
        name: 'cameraNavigation',
        options: filteredCameraNavigationOptions.value,
      });
    }
  }

  return options;
};

const getUnrealRules = (options) => {
  const allRules = {
    json: [
      {
        required: true,
        type: 'object',
      },
    ],
    isUnrealPanoramasCaptureEnabled: [],
    isUnrealTopViewsCaptureEnabled: [],
    isUnrealTopDownViewsCaptureEnabled: [],
    unrealTopDownViewCount: [
      {
        min: 1,
        max: 121,
        type: 'number',
      },
    ],
    unrealRtxPreset: [],
    unrealRtxPresetTop: [],
    unrealEnvironmentId: null,
    panoramasType: null,
    isRulerEnabled: false,
    isInteractiveTopViewEnabled: false,
    isTopViewAsMiniPlanEnabled: false,
    isHideOptionalFurnitureEnabled: false,
    isTransparentTopViewEnabled: false,
    cameraIso: null,
    projectionMode: null,
    losslessFormat: null,
    cameraNavigation: null,
    isUnrealMiddleCutCaptureEnabled: [],
    unrealMiddleCutHeight: [
      {
        min: 0,
        max: 280,
        type: 'number',
      },
    ],
  };

  return getRulesForOptions(allRules, options);
};

const getOptionsAndRules = (steps) => getOptionsAndRulesBase({
  steps,
  getNeuralOptions,
  getNeuralRules,
  getFurnitureOptions,
  getFurnitureRules,
  getUnrealOptions,
  getUnrealRules,
});

const getFormState = () => ({
  steps: [],

  plans: [],
  mask: null,
  isNeuralLogEnabled: false,

  json: null,

  isFurnitureLightsEnabled: true,
  isFurnitureCurtainsRadiatorsEnabled: true,
  isFurnitureEnabled: true,
  isFurnitureGlassWallsCollapseEnabled: true,
  furnitureDesignName: 'TestDesign',
  isUnrealPanoramasCaptureEnabled: true,
  isUnrealTopViewsCaptureEnabled: true,
  isUnrealTopDownViewsCaptureEnabled: false,
  unrealTopDownViewCount: 1,
  unrealRtxPreset: RtxPreset['No RTX Preview'],
  unrealRtxPresetTop: RtxPreset['No RTX Preview'],
  unrealEnvironmentId: null,
  panoramasType: PanoramasType.Sphere,
  isRulerEnabled: false,
  isInteractiveTopViewEnabled: false,
  isTopViewAsMiniPlanEnabled: false,
  isHideOptionalFurnitureEnabled: false,
  isTransparentTopViewEnabled: false,
  isWebpEnabled: true,
  cameraIso: 500,
  projectionMode: TopViewsProjectionMode.Perspective,
  losslessFormat: LosslessFormat.Exr,
  cameraNavigation: CameraNavigationMode.Classic,
  isUnrealMiddleCutCaptureEnabled: true,
  unrealMiddleCutHeight: 150,
  styleId: 1,
  buildPriority: BuildPriority.Normal,
});

export {
  Steps,
  OptionTypes,
  getOptionsAndRules,
  getFormState,
};

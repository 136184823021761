<template>
  <a-modal
    :open="visible"
    width="1000px"
    title="Вставьте ссылки на планировки для переключения в виджете"
    centered
    ok-text="Подтвердить"
    cancel-text="Отмена"
    @cancel="closeModal">
    <template #footer>
      <a-button
        @click="closeModal">
        Отмена
      </a-button>
      <a-button
        type="primary"
        @click="createWidgetVariant">
        Создать
      </a-button>
    </template>

    <a-table
      :pagination="false"
      :data-source="crmPlanWidgetVariantData">
      <a-table-column
        key="name"
        :width="30"
        title="Название"
        data-index="name" />
      <a-table-column
        key="plan_id"
        :width="300"
        title="Ссылка"
        data-index="plan_id" />
      <a-table-column
        :width="30"
        title="Действия">
        <template #default="{ record }">
          <a-button
            type="link"
            size="small"
            @click="updateModal.open(record)">
            Редактировать
          </a-button>
          <a-button
            type="link"
            size="small"
            @click="deleteWidgetVariant(record.id)">
            Удалить
          </a-button>
        </template>
      </a-table-column>
    </a-table>

    <!-- UPDATE MODAL -->
    <a-modal
      v-model:open="updateModal.isOpened"
      centered
      title="Редактирование варианта"
      ok-text="Сохранить"
      cancel-text="Отменить"
      @ok="updateWidgetVariant">
      <a-form :model="updateModal.form">
        <a-form-item label="Название">
          <a-input
            v-model:value="updateModal.form.name"
            placeholder="Введите название" />
        </a-form-item>

        <a-form-item label="ID планировки">
          <a-input
            v-model:value="updateModal.form.plan_id"
            placeholder="Введите UUID" />
        </a-form-item>
      </a-form>
    </a-modal>

    <a-divider>
      Создание варианта
    </a-divider>

    <a-form
      ref="formRef"
      :model="addVariantsForm"
      @submit.prevent="createWidgetVariant">
      <a-row>
        <a-col :span="24">
          <template
            v-for="(variant, variantIndex) in addVariantsForm.variants"
            :key="variant.id">
            <a-row :gutter="[16,16]">
              <a-col
                v-if="isRenovationCheckboxVisible"
                :span="2">
                <a-form-item :name="['variants', variantIndex, 'is_renovation']">
                  <a-checkbox
                    v-model:value="variant.is_renovation"
                    style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :name="['variants', variantIndex, 'name']">
                  <a-input
                    v-model:value="variant.name"
                    placeholder="Введите название стиля"
                    style="width: 100%" />
                </a-form-item>
              </a-col>

              <a-col :span="addVariantsForm.variants.length > 1 ? 11 : 12">
                <a-form-item
                  :name="['variants', variantIndex, 'plan_id']">
                  <a-input
                    v-model:value="variant.plan_id"
                    placeholder="Вставьте id планировки" />
                </a-form-item>
              </a-col>

              <a-col :span="1">
                <DeleteOutlined
                  v-if="addVariantsForm.variants.length > 1 && variantIndex > 0"
                  class="dynamic-delete-button"
                  :disabled="addVariantsForm.variants.length === 1"
                  @click="removeLink()" />
              </a-col>
            </a-row>
          </template>
        </a-col>

        <a-col :span="24">
          <a-button
            :disabled="isAddButtonDisabled"
            type="dashed"
            block
            @click="addLink">
            <template #icon>
              <PlusOutlined />
            </template>
            Добавить ссылку
          </a-button>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script setup>

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue';
import {
  reactive, ref, watch,
} from 'vue';
import { api } from '@/api';
import { clearForm } from '@/helpers/clearForm';
import { message } from 'ant-design-vue';
import getFlag from '@/helpers/getFlag';

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  servicePlanId: {
    type: String,
    default: '',
  },
  crmPlanId: {
    type: Number,
    default: null,
  },
  crmPlanWidgetVariants: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['close']);
const isRenovationCheckboxVisible = getFlag('isRenovationCheckboxVisible');

const formRef = ref(null);

const crmPlanWidgetVariantData = ref(props.crmPlanWidgetVariants || []);

watch(() => props.crmPlanWidgetVariants, (newVal) => {
  crmPlanWidgetVariantData.value = newVal || [];
}, { immediate: true });

const addVariantsForm = reactive({
  variants: [{
    name: null,
    plan_id: null,
    is_renovation: true,
  }],
});

const resetForm = () => {
  addVariantsForm.variants = [{
    name: null,
    plan_id: null,
    is_renovation: true,
  }];
  formRef.value.clearValidate();
};

const addLink = () => {
  addVariantsForm.variants.push({
    name: null,
    plan_id: null,
    is_renovation: false,
  });
};

const closeModal = () => {
  emit('close');
  resetForm();
};

const handleApiError = (error) => {
  if (error?.response?.status === 422) {
    const { errors } = error.response.data;

    if (errors?.plan_id) {
      message.error('Некорректный ID планировки. Проверьте формат UUID');
      return;
    }

    message.error('Ошибка валидации данных');
  } else {
    message.error(error?.message || 'Произошла ошибка');
  }
};

const createWidgetVariant = async () => {
  try {
    const response = await api.createWidgetVariant(addVariantsForm, props.crmPlanId);

    if (response.error) {
      handleApiError(response.error);
      return;
    }

    const newVariants = Array.isArray(response.data) ? response.data : [response.data];

    crmPlanWidgetVariantData.value = [...crmPlanWidgetVariantData.value, ...newVariants];

    resetForm();
    message.success('Варианты успешно созданы!');
  } catch (error) {
    handleApiError(error);
  }
};

const deleteWidgetVariant = async (widgetVariantId) => {
  try {
    await api.deleteWidgetVariant(widgetVariantId);
    message.success('Вариант успешно удалён!');

    crmPlanWidgetVariantData.value = crmPlanWidgetVariantData.value.filter(
      (variant) => variant.id !== widgetVariantId,
    );
  } catch (error) {
    message.error(error?.message || 'Ошибка при удалении варианта');
  }
};

const updateModal = reactive({
  isOpened: false,
  isUpdating: false,
  form: {
    id: null,
    crm_plan_id: props.crmPlanId,
    plan_id: '',
    name: '',
    is_renovation: true,
  },
});

updateModal.open = (record) => {
  updateModal.form.id = record.id;
  updateModal.form.crm_plan_id = props.crmPlanId;
  updateModal.form.plan_id = record.plan_id;
  updateModal.form.name = record.name;
  updateModal.form.is_renovation = record.is_renovation;

  updateModal.isOpened = true;
};

updateModal.close = () => {
  clearForm(updateModal.form);
  updateModal.isOpened = false;
};

const updateWidgetVariant = async () => {
  try {
    updateModal.isUpdating = true;

    const requestData = {
      id: updateModal.form.id,
      crm_plan_id: updateModal.form.crm_plan_id,
      plan_id: updateModal.form.plan_id,
      name: updateModal.form.name,
      is_renovation: updateModal.form.is_renovation,
    };

    await api.updateWidgetVariant(requestData, updateModal.form.id);

    crmPlanWidgetVariantData.value = crmPlanWidgetVariantData.value
      .map((variant) => (variant.id === updateModal.form.id ? { ...variant, ...requestData } : variant));

    message.success('Вариант успешно обновлён!');
    updateModal.isOpened = false;
  } catch (error) {
    handleApiError(error);
  } finally {
    updateModal.isUpdating = false;
  }
};

const deleteModal = reactive({
  isOpened: false,
  isUpdating: false,
  form: {
    widgetVariantId: null,
  },
});

deleteModal.open = () => {
  deleteModal.form.widgetVariantId = props.crmPlanWidgetVariant.id;
  deleteModal.isOpened = true;
};

deleteModal.close = () => {
  clearForm(deleteModal.form);
  deleteModal.isOpened = false;
};

const removeLink = (index) => {
  addVariantsForm.variants.splice(index, 1);
};

</script>
